
:root{
    --mainDark: #343a40;
    --mainGrey: rgba(109, 109, 109);
    --mainLightGrey: rgba(172, 172, 172);
    --mainDarkGreen: #198754;
    --mainDeepGreen:rgba(13, 112, 36, 0.884);
    --mainGreen: rgba(93, 181, 113, 0.32);
    --mainLightGreen:rgba(20, 215, 33, 0.03);
    --mainWhite: #fff;
    --mainBlue: #007bff;
    --mainGold: #a8a632;
    --chTitleFont: Tahoma,Helvetica,Arial,sans-serif;
    --chTextFont: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;

    /* --chTitleFont: "SimHei", "STHeiti";
    --chTextFont: "SimSun", "STSong"; */
  }

  .navbar-toggler-focus {
    outline: none;
  }

  .navbar-toggler {
    border: none;
  }

  /* .card {
    background-color: var(--mainLightGreen);
  } */